@import url('https://fonts.googleapis.com/css2?family=Cousine&family=Roboto:wght@100;400;500&display=swap');

body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #18191A;
  font-size: 12px;

  /* touch-action: none; */
  /* -webkit-touch-callout: none; */
  /* -webkit-user-select: none; */
  /* -khtml-user-select: none; */
  /* -moz-user-select: none; */
  /* -ms-user-select: none; */
  /* user-select: none; */
}

* {
  color: #A7A8A8;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  cursor: default;
  /* outline: 1px #ff000020 solid; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
